import React from 'react';
import { PropTypes, oneOfType } from 'prop-types';
import { Modal } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
// eslint-disable-next-line css-modules/no-unused-class
import s from './Modal.scss';

class AlertComp extends React.Component {
  static propTypes = {
    footer: PropTypes.node,
    title: PropTypes.node,
    msg: PropTypes.node,
    show: PropTypes.bool,
    className: PropTypes.string,
    dialogClassName: PropTypes.string,
    headerClassName: PropTypes.string,
    bodyClassName: PropTypes.string,
    footerClassName: PropTypes.string,
    backdrop: oneOfType([PropTypes.string, PropTypes.bool]),
    hide: PropTypes.func,
  };

  static defaultProps = {
    backdrop: true,
    footer: null,
    title: null,
    msg: null,
    show: false,
    className: s.defaultAlert,
    dialogClassName: s.defaultDialog,
    headerClassName: s.defaultHeader,
    bodyClassName: s.defaultBody,
    footerClassName: s.defaultFooter,
    hide: () => {},
  };

  render() {
    const {
      show,
      className,
      dialogClassName,
      headerClassName,
      bodyClassName,
      footerClassName,
      title,
      msg,
      footer,
      backdrop,
      hide,
    } = this.props;

    return (
      <Modal
        show={show}
        className={className}
        dialogClassName={dialogClassName}
        backdrop={backdrop}
        onHide={hide}
        enforceFocus={false}
      >
        <Modal.Header className={headerClassName}>
          {title || <h1>Header</h1>}
        </Modal.Header>
        <Modal.Body className={bodyClassName}>{msg || <p>Body</p>}</Modal.Body>
        <Modal.Footer className={footerClassName}>
          {footer || (
            <button className="btn-primary" onClick={hide}>
              Close
            </button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

/* eslint-disable react/no-multi-comp */
class SuccessAlertComp extends React.Component {
  render() {
    return (
      <Alert
        {...this.props}
        className={s.successAlert}
        dialogClassName={s.successDialog}
        headerClassName={s.successHeader}
        bodyClassName={s.successBody}
      />
    );
  }
}

const SuccessAlert = withStyles(s)(SuccessAlertComp);
const Alert = withStyles(s)(AlertComp);
export { SuccessAlert, Alert };
