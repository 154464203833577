import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Panel, PanelGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FaFolder from 'react-icons/lib/fa/folder';
import FaFolderOpen from 'react-icons/lib/fa/folder-open';
import FaChevronDown from 'react-icons/lib/fa/chevron-down';
import FaChevronLeft from 'react-icons/lib/fa/chevron-left';
import FaChevronRight from 'react-icons/lib/fa/chevron-right';
import { FormattedMessage } from 'react-intl';

import AuthenticationWrapper from 'components/Auth/AuthenticationWrapper';
import gate from 'components/Auth/gate';
import allRoles from 'core/roles';
import messages from './messages';
import s from './Sidebar.scss';

const navTree = [
  {
    name: messages.dashboard,
    subNavs: [
      {
        name: messages.dashboard,
        path: '/admin/dashboard',
      },
    ],
    openIcon: FaFolderOpen,
    closedIcon: FaFolder,
  },
  {
    name: messages.users,
    roles: [allRoles.ADMIN],
    subNavs: [
      {
        name: messages.users,
        path: '/admin/users',
      },
    ],
    openIcon: FaFolderOpen,
    closedIcon: FaFolder,
  },
  {
    name: messages.logs,
    roles: [allRoles.ADMIN],
    subNavs: [
      {
        name: messages.requestLogs,
        path: '/admin/request-logs',
      },
    ],
    openIcon: FaFolderOpen,
    closedIcon: FaFolder,
  },
];

class Sidebar extends React.Component {
  static propTypes = {
    me: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props, context) {
    super(props, context);

    this.handleSelect = this.handleSelect.bind(this);

    this.state = {
      activeKey: null,
    };
  }

  handleSelect(activeKey) {
    this.setState({ activeKey });
  }

  render() {
    const { me } = this.props;
    return (
      <PanelGroup
        className={s.sideNav}
        accordion
        id="sidebar-nav"
        activeKey={this.state.activeKey}
        onSelect={this.handleSelect}
      >
        {navTree.map((nav, index) => {
          if (typeof nav === 'function') {
            // eslint-disable-next-line no-param-reassign
            nav = nav(me);
          }
          const showNav =
            (!nav.roles ||
              gate.isAllowed({ user: me, allowedRoles: nav.roles })) &&
            (!nav.filter || nav.filter(this.props.me));
          if (!showNav) return null;

          const key = `nav_${index}`;
          const Icon =
            this.state.activeKey === index ? nav.openIcon : nav.closedIcon;
          const IconChevron =
            this.state.activeKey === index ? FaChevronDown : FaChevronLeft;
          const navTitle =
            this.state.activeKey === index ? s.navTitleSelected : s.navTitle;

          return (
            <Panel className={s.navPanel} key={key} eventKey={index}>
              <Panel.Heading className={s.navHeading}>
                <Panel.Title className={navTitle} toggle>
                  <Icon className="fa--prepended" />{' '}
                  <span>
                    <FormattedMessage {...nav.name} />
                  </span>
                  <IconChevron className={s.iconRight} />
                </Panel.Title>
              </Panel.Heading>
              <Panel.Body className={s.navBody} collapsible>
                {nav.subNavs
                  .filter(
                    subNav =>
                      !subNav.roles ||
                      gate.isAllowed({ user: me, allowedRoles: nav.roles }),
                  )
                  .map((subNav, subIndex) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className={s.subNav} key={`subNav_${subIndex}`}>
                      <Link to={subNav.path}>
                        <FaChevronRight className="fa--prepended" />
                        <FormattedMessage {...subNav.name} />
                      </Link>
                    </div>
                  ))}
              </Panel.Body>
            </Panel>
          );
        })}
      </PanelGroup>
    );
  }
}

export default AuthenticationWrapper(withStyles(s)(Sidebar));
