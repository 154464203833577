import { defineMessages } from 'react-intl';

const messages = defineMessages({
  date: {
    id: 'requestLog.date',
    defaultMessage: 'Date',
    description: 'Header for date column in request logs table',
  },
  type: {
    id: 'requestLog.type',
    defaultMessage: 'Result',
    description: 'Header for type column in request logs table',
  },
  host: {
    id: 'requestLog.host',
    defaultMessage: 'Host',
    description: 'Header for host column in request logs table',
  },
  info: {
    id: 'requestLog.info',
    defaultMessage: 'Info',
    description: 'Header for info column in request logs table',
  },
  user: {
    id: 'requestLog.user',
    defaultMessage: 'User',
    description: 'Header for user column in request logs table',
  },
  requestData: {
    id: 'requestLog.requestData',
    defaultMessage: 'Request Data',
    description: 'Header for request data column in request logs table',
  },
  view: {
    id: 'requestLog.view',
    defaultMessage: 'View',
    description: 'Header for view column in request logs table',
  },
});

export default messages;
