import Login from './login';
import Admin from './admin';

const routes = [
  {
    path: '/',
    component: Login,
    exact: true,
  },
  {
    path: '/login',
    component: Login,
    exact: true,
  },
  {
    path: '/admin',
    component: Admin,
  },
];

export default routes;
