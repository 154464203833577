import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Navbar, Nav, NavDropdown, MenuItem } from 'react-bootstrap';
import { compose } from 'react-apollo';
import { Link } from 'react-router-dom';

import AuthenticationWrapper from 'components/Auth/AuthenticationWrapper';
import { withLogout } from 'components/Auth/withLogout';
import s from './TopNavigation.scss'; // eslint-disable-line css-modules/no-unused-class

class TopNavigation extends React.Component {
  static propTypes = {
    me: PropTypes.shape({
      username: PropTypes.string.isRequired,
    }).isRequired,
    logout: PropTypes.func.isRequired,
  };

  handleSelect(eventKey) {
    switch (eventKey) {
      case 2.1:
        this.props.logout();
        break;
      default:
    }
  }

  render() {
    const {
      me: { username },
    } = this.props;

    return (
      <Navbar
        className={s.navbar}
        fixedTop
        fluid
        collapseOnSelect
        onSelect={k => this.handleSelect(k)}
      >
        <Navbar.Header>
          <Navbar.Brand className={s.logoContainer}>
            <Link to="/admin/dashboard">
              <div className={`${s.logoIcon} ${s.hideMobile}`}>
                <img src="/logo.png" alt="" />
                <span>- Admin</span>
              </div>
              <div className={`${s.logoIcon} ${s.showMobile}`}>
                <img src="/logo_no_text.png" alt="" />
              </div>
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse className={s.navbarCollapse}>
          <Nav pullRight>
            <NavDropdown
              className={s.navDropDown}
              eventKey={1}
              title={username}
              id="nav-dropdown"
            >
              <MenuItem eventKey={2.1}>Logout</MenuItem>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default compose(
  AuthenticationWrapper,
  withLogout,
  withStyles(s),
)(TopNavigation);
