/* eslint-disable camelcase */

/**
 * HOC that checks if the current user is logged in.
 * If so, it renders the wrapped component and
 * passes the current user's information to
 * it via the 'me' prop.
 *
 * Otherwise, it redirects to the homepage.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router';
import { meQuery } from 'components/Auth/withLogin';
import Loading from 'components/Loading';

const AuthenticationWrapper = WrappedComponent => {
  class WithAuthentication extends React.Component {
    static propTypes = {
      meData: PropTypes.shape({
        loading: PropTypes.bool.isRequired,
        me: PropTypes.shape({
          id: PropTypes.string.isRequired,
          roles: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string.isRequired,
              name: PropTypes.string.isRequired,
            }),
          ),
        }),
      }).isRequired,
      match: PropTypes.shape({
        path: PropTypes.string.isRequired,
      }).isRequired,
    };

    render() {
      const {
        meData: { loading, api_me },
      } = this.props;

      if (loading) return <Loading />;
      if (!api_me) {
        return <Redirect to="/login" />;
      }

      return <WrappedComponent {...this.props} me={api_me} />;
    }
  }

  return compose(
    graphql(meQuery, {
      name: 'meData',
    }),
  )(withRouter(WithAuthentication));
};

export default AuthenticationWrapper;
