import { defineMessages } from 'react-intl';

const messages = defineMessages({
  userDataTabTitle: {
    id: 'userEdit.tabs.userDataTabTitle',
    defaultMessage: 'User Data',
    description: 'Title for user data tab in user edit',
  },
  settingsTabTitle: {
    id: 'userEdit.tabs.settingsTabTitle',
    defaultMessage: 'Settings',
    description: 'Title for settings tab in user edit',
  },
});

export default messages;
