import { defineMessages } from 'react-intl';

const messages = defineMessages({
  username: {
    id: 'user.username',
    defaultMessage: 'Username',
    description: 'Label for username in user',
  },
  active: {
    id: 'user.active',
    defaultMessage: 'Active',
    description: 'Label for active in user',
  },
});

export default messages;
