import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { Row } from 'react-bootstrap';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import Loading from 'components/Loading';
import AuthenticationWrapper from 'components/Auth/AuthenticationWrapper';
import meQuery from 'components/Auth/queries/meQuery';
import s from './Dashboard.scss';
import ApiTokenGenerator from './ApiTokenGenerator';

class Dashboard extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      api_me: PropTypes.shape({
        username: PropTypes.string.isRequired,
      }),
    }).isRequired,
  };
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  render() {
    const {
      data: { loading },
    } = this.props;
    if (loading) return <Loading />;

    return (
      <Row>
        <ApiTokenGenerator />
      </Row>
    );
  }
}

export default compose(
  graphql(meQuery, {
    name: 'data',
  }),
)(AuthenticationWrapper(withStyles(s)(Dashboard)));
