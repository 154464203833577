import { defineMessages } from 'react-intl';

const messages = defineMessages({
  required: {
    id: 'validation.required',
    defaultMessage: 'This field is required',
    description: 'Validation error message for required fields',
  },
  submit: {
    id: 'form.submit',
    defaultMessage: 'Save',
    description: 'Default submit button label',
  },
  cancel: {
    id: 'form.cancel',
    defaultMessage: 'Cancel',
    description: 'Default cancel button label',
  },
  unsavedChanges: {
    id: 'form.unsavedChanges',
    defaultMessage: 'This form contains unsaved changes',
    description: 'Info message if form contains unsaved changes',
  },
  add: {
    id: 'form.add',
    defaultMessage: 'Add',
    description: 'Default value for add (entry) buttons',
  },
  remove: {
    id: 'form.remove',
    defaultMessage: 'Delete',
    description: 'Default value for remove (entry) buttons',
  },
  pleaseChoose: {
    id: 'form.pleaseChoose',
    defaultMessage: 'Please choose ...',
    description: 'Default value for empty option for select fields',
  },
});

export default messages;
