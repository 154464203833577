import { defineMessages } from 'react-intl';

const messages = defineMessages({
  dashboard: {
    id: 'sidebar.dashboard',
    defaultMessage: 'Dashboard',
    description: 'Label for dashboard link in sidebar',
  },
  users: {
    id: 'sidebar.users',
    defaultMessage: 'Users',
    description: 'Label for users link in sidebar',
  },
  logs: {
    id: 'sidebar.logs',
    defaultMessage: 'Logs',
    description: 'Label for logs link in sidebar',
  },
  requestLogs: {
    id: 'sidebar.logs.requestLogs',
    defaultMessage: 'Request Logs',
    description: 'Label for request logs link in sidebar',
  },
});

export default messages;
