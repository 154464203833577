import { defineMessages } from 'react-intl';

export default defineMessages({
  apiKeyId: {
    id: 'dashboard.apiTokenGenerator.apiKeyId',
    defaultMessage: 'Api Key ID',
    description: 'Label for apikey id field in api token generator form',
  },
  secret: {
    id: 'dashboard.apiTokenGenerator.secret',
    defaultMessage: 'Api Key Secret',
    description: 'Label for apikey id field in api token generator form',
  },
  generate: {
    id: 'dashboard.apiTokenGenerator.generate',
    defaultMessage: 'Generate',
    description: 'Label for generate button in api token generator form',
  },
  apiTokenGenerator: {
    id: 'dashboard.apiTokenGenerator.apiTokenGenerator',
    defaultMessage: 'Api Token Generator',
    description: 'Label for header in api token generator form',
  },
  modalHeaderSuccess: {
    id: 'dashboard.apiTokenGenerator.modalHeaderSuccess',
    defaultMessage: 'Success',
    description: 'text for product selection modal.',
  },
  close: {
    id: 'dashboard.apiTokenGenerator.close',
    defaultMessage: 'Close',
    description: 'text for close button in api generator modal.',
  },
  copy: {
    id: 'dashboard.apiTokenGenerator.copy',
    defaultMessage: 'Copy To Clipboard',
    description: 'text for copy to clipbaord button in api generator modal.',
  },
  successMessage1: {
    id: 'dashboard.apiTokenGenerator.successMessage1',
    defaultMessage: `
      Successfully generated Api Token.
    `,
    description: 'text for success message in api generator modal.',
  },
});
