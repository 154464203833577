import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { injectIntl, intlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import { FaExclamationTriangle, FaEye } from 'react-icons/lib/fa';
import moment from 'moment';

import Loading from 'components/Loading';
import Table from 'components/Table';
import messages from './messages';
import { DB_DATE_FORMAT } from '../../util';

// const dateFormat = 'DD.MM.YYYY';
const dateFormatExact = 'DD.MM.YYYY HH:mm:ss';

const sortDates = format => (a, b) => {
  if (a === b) return 0;

  const timeA = moment(a, format);
  const timeB = moment(b, format);

  if (!timeA.isValid()) return -1;
  if (!timeB.isValid()) return 1;

  if (timeA.isAfter(timeB)) return 1;
  return -1;
};

class RequestLogsTable extends React.Component {
  static getValueFromRequestData({ requestLog, key }) {
    const requestData = JSON.parse(requestLog.requestData);
    if (requestData[key]) {
      return requestData[key];
    }
    return null;
  }

  static getValueFromRequestHeaders({ requestLog, key }) {
    const headers = RequestLogsTable.getValueFromRequestData({
      requestLog,
      key: 'headers',
    });
    if (headers && headers[key]) {
      return headers[key];
    }
    return null;
  }

  static propTypes = {
    requestLogsData: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.func.isRequired,
      requestLogs: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
        }),
      ),
    }).isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
  };

  render() {
    const {
      requestLogsData: { loading, requestLogs },
      match: { path },
      intl,
    } = this.props;

    if (loading) return <Loading />;

    return (
      <Table
        tableId="request-logs-table"
        data={requestLogs}
        columns={[
          {
            Header: intl.formatMessage(messages.date),
            id: 'date',
            accessor: requestLog =>
              moment(requestLog.createdAt, DB_DATE_FORMAT).format(
                'DD.MM.YYYY HH:mm:ss',
              ),
            sortMethod: sortDates(dateFormatExact),
          },
          {
            Header: intl.formatMessage(messages.type),
            id: 'type',
            accessor: requestLog => {
              if (requestLog.type === 'UNAUTHORIZED_ACCESS') {
                return (
                  <span style={{ color: 'red' }}>
                    <FaExclamationTriangle /> {requestLog.type}
                  </span>
                );
              }
              return requestLog.type;
            },
          },
          {
            Header: intl.formatMessage(messages.host),
            id: 'host',
            accessor: requestLog =>
              RequestLogsTable.getValueFromRequestHeaders({
                requestLog,
                key: 'host',
              }),
          },
          { Header: 'Info', accessor: 'message' },
          {
            Header: intl.formatMessage(messages.user),
            id: 'user',
            accessor: requestLog => {
              const user = RequestLogsTable.getValueFromRequestData({
                requestLog,
                key: 'user',
              });
              if (user) return user.username;
              return null;
            },
          },
          {
            Header: intl.formatMessage(messages.view),
            id: 'view',
            Cell: ({ original }) => (
              <Link to={`${path}${original.id}`}>
                <FaEye />
              </Link>
            ),
          },
        ]}
        tableProps={{
          defaultPageSize: 10,
          defaultSorted: [
            {
              id: 'date',
              desc: true,
            },
          ],
        }}
      />
    );
  }
}

const requestLogsDataQuery = gql`
  query requestLogsData {
    requestLogs {
      id
      type
      message
      requestData
      createdAt
    }
  }
`;

export default compose(
  graphql(requestLogsDataQuery, {
    name: 'requestLogsData',
  }),
)(injectIntl(RequestLogsTable));
