import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import baseStyles from '../../styles/app.scss';
import s from './AdminLayout.scss';
import TopNavigation from './TopNavigation';
import Sidebar from './Sidebar';

class AdminLayout extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  render() {
    return (
      <div className="container-fluid" style={{ padding: '0' }}>
        <TopNavigation />
        <div style={{ float: 'left' }}>
          <Sidebar />
        </div>
        <div style={{ float: 'left', marginTop: '100px' }}>
          <div
            className="content"
            style={{
              marginTop: '20px',
              marginBottom: '20px',
              marginLeft: '250px',
              width: 'calc(100vw - 270px)',
            }}
          >
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(baseStyles, s)(AdminLayout);
