import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import render from 'components/ReduxForm/renderField';
import validations from 'components/ReduxForm/validations';
import * as normalizers from 'components/ReduxForm/normalizers';
import formMessages from 'components/ReduxForm/messages';

import userMessages from '../messages';

const validate = values => {
  const errors = {};

  if (!validations.required(values.username)) {
    errors.username = <FormattedMessage {...formMessages.required} />;
  }

  return errors;
};

class EditUserForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    intl: intlShape.isRequired,
  };

  render() {
    return (
      <form onSubmit={this.props.handleSubmit}>
        <fieldset>
          <Field
            id="username"
            name="username"
            type="text"
            label={<FormattedMessage {...userMessages.username} />}
            component={render.renderInput}
            disabled
          />
          <Field
            id="active"
            name="active"
            type="checkbox"
            label={<FormattedMessage {...userMessages.active} />}
            component={render.renderCheckbox}
            normalize={normalizers.booleanNormalizer}
          />
          <button
            type="submit"
            className="btn-secondary btn-round btn-large"
            style={{ marginTop: '20px' }}
            disabled={this.props.submitting}
          >
            <FormattedMessage {...formMessages.submit} />
          </button>
        </fieldset>
      </form>
    );
  }
}

export default reduxForm({
  form: 'editUserForm',
  validate,
})(injectIntl(EditUserForm));
