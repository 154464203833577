import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { injectIntl, intlShape } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './Login.scss';
import messages from '../messages';
import LoginForm from './LoginForm';
import { withLogin } from '../withLogin';
import TopNavigationLogin from '../../AdminLayout/TopNavigationLogin';

class Login extends React.Component {
  static propTypes = {
    login: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
  };

  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      errors: [],
    };

    this.submitLoginForm = this.submitLoginForm.bind(this);
  }

  setErrors(errors) {
    this.setState({
      errors: errors.map(err => err.message),
    });
  }

  async submitLoginForm({ username, password }) {
    const { login, history, intl } = this.props;
    const result = await login(username, password);

    if (!result) {
      this.setErrors([
        { message: intl.formatMessage(messages.invalidCredentials) },
      ]);
    } else {
      history.push({ pathname: '/admin/dashboard' });
    }
  }

  render() {
    return (
      <div className={s.loginContainer}>
        <TopNavigationLogin />
        <LoginForm onSubmit={this.submitLoginForm} errors={this.state.errors} />
        <img className={s.fishInWave} src="/fishInWave.svg" alt="" />
      </div>
    );
  }
}

export default withRouter(withLogin(injectIntl(withStyles(s)(Login))));
