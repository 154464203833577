import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, reduxForm } from 'redux-form';

import validations from 'components/ReduxForm/validations';
import validationMessages from 'components/ReduxForm/messages';
import render from 'components/ReduxForm/renderField';
import messages from './messages';

const validate = formValues => {
  const errors = {};
  if (!validations.required(formValues.apiKeyId)) {
    errors.apiKeyId = <FormattedMessage {...validationMessages.required} />;
  }

  if (!validations.required(formValues.secret)) {
    errors.secret = <FormattedMessage {...validationMessages.required} />;
  }
  return errors;
};

class ApiTokenGeneratorForm extends React.Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    generatedApiToken: PropTypes.string,
  };

  static defaultProps = {
    generatedApiToken: null,
  };

  render() {
    return (
      <form
        onSubmit={this.props.handleSubmit}
        style={{ paddingLeft: '15px', paddingRight: '15px' }}
      >
        <fieldset>
          <Row style={{ marginBottom: '20px' }}>
            <Col xs={12} md={6} mdOffset={1} lg={4} lgOffset={1}>
              <h3>
                <FormattedMessage {...messages.apiTokenGenerator} />
              </h3>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} mdOffset={1} lg={4} lgOffset={1}>
              <Field
                id="apiKeyId"
                name="apiKeyId"
                label={<FormattedMessage {...messages.apiKeyId} />}
                component={render.renderInput}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} mdOffset={1} lg={4} lgOffset={1}>
              <Field
                id="secret"
                name="secret"
                label={<FormattedMessage {...messages.secret} />}
                component={render.renderInput}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: '10px' }}>
            <Col xs={12} md={6} mdOffset={1} lg={4} lgOffset={1}>
              <button
                type="submit"
                className="btn-secondary btn-round btn-large"
              >
                <FormattedMessage {...messages.generate} />
              </button>
            </Col>
          </Row>
        </fieldset>
      </form>
    );
  }
}

export default reduxForm({
  form: 'apiTokenGeneratorForm',
  validate,
})(injectIntl(ApiTokenGeneratorForm));
