exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2b5Q9{position:fixed;top:100px;left:0;width:230px;z-index:1;height:100%;background-color:#fff;margin:0;padding:5px 0 0}@media (max-width:1200px){._2b5Q9{top:75px}}._2b5Q9 .OiqKG{padding-top:5px;padding-bottom:5px}._2b5Q9 ._25-XX,._2b5Q9 .OiqKG,._2b5Q9 .Z5LJ1{background-color:#fff;color:#333;border:none!important}._2b5Q9 ._25-XX,._2b5Q9 .Z5LJ1{margin:0;padding:0}._2b5Q9 ._25-XX a{border-left:4px solid #fff!important}._2b5Q9 ._25-XX a,._2b5Q9 .WeVqP a{display:block;padding:10px;color:#333!important}._2b5Q9 ._25-XX a:hover,._2b5Q9 .WeVqP a,._2b5Q9 .WeVqP a:hover{background-color:#efefef;border-left:4px solid #0db5ff!important}._2b5Q9 ._2jdu3{margin-top:0;padding-left:15px;padding-top:5px;padding-bottom:5px;background-color:#fff;color:#333;border:none!important}._2b5Q9 ._2jdu3 ._2wFPY a{padding:5px 0;display:block;color:#333}._2b5Q9 ._2jdu3 ._2wFPY a:hover{color:#b4b4b4}._3qcAm{float:right}", ""]);

// exports
exports.locals = {
	"teal": "#00dcb2",
	"grey": "#b4b4b4",
	"sideNav": "_2b5Q9",
	"navPanel": "OiqKG",
	"navHeading": "Z5LJ1",
	"navTitle": "_25-XX",
	"navTitleSelected": "WeVqP",
	"navBody": "_2jdu3",
	"subNav": "_2wFPY",
	"iconRight": "_3qcAm"
};