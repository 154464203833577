/* eslint-disable import/prefer-default-export */

export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';
export const SET_LOCALE_START = 'SET_LOCALE_START';
export const SET_LOCALE_SUCCESS = 'SET_LOCALE_SUCCESS';
export const SET_LOCALE_ERROR = 'SET_LOCALE_ERROR';
export const SET_LICENSE_STATE = 'SET_LICENSE_STATE';

export const API_ENDPOINT_RECOMMENDATION = 'RECOMMENDATION';
export const API_ENDPOINT_PREDICTION = 'PREDICTION';
export const API_ENDPOINT_RECALIBRATION = 'RECALIBRATION';

export const PRODUCT_KEY_RECOMMENDATION = 'danube-api-recommendation';
export const PRODUCT_KEY_PREDICTION = 'danube-api-prediction';
export const PRODUCT_KEY_RECALIBRATION = 'danube-api-recalibration';

export const LICENSE_API_KEY_STATISTICS_UNIT_PERCENTAGE = 'PERCENTAGE';
