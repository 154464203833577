/* eslint-disable camelcase */

import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl';
import { Row, Col, Table } from 'react-bootstrap';
import { FaExclamationTriangle } from 'react-icons/lib/fa';
import JSONPretty from 'react-json-pretty';
import moment from 'moment';

import Loading from 'components/Loading';
import messages from './messages';
import RequestLogsTable from './Table';
import { DB_DATE_FORMAT } from '../../util';

class RequestLogView extends React.Component {
  static propTypes = {
    requestLogQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.func.isRequired,
      requestLog: PropTypes.shape({
        id: PropTypes.string.isRequired,
        createdAt: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        requestData: PropTypes.string.isRequired,
      }),
    }).isRequired,
    // eslint-disable-next-line react/no-unused-prop-types
    match: PropTypes.shape({
      params: PropTypes.shape({
        requestLogname: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.getUsername = this.getUsername.bind(this);
  }

  getUsername() {
    const {
      requestLogQuery: { requestLog },
    } = this.props;
    const user = RequestLogsTable.getValueFromRequestData({
      requestLog,
      key: 'user',
    });
    if (user) return user.username;
    return null;
  }

  render() {
    const {
      requestLogQuery: { loading, requestLog },
    } = this.props;
    if (loading) return <Loading />;

    return (
      <div>
        <Row>
          <Col xs={12} md={6}>
            <Table bordered hover style={{ backgroundColor: 'white' }}>
              <tbody>
                <tr>
                  <td>
                    <strong>
                      <FormattedMessage {...messages.date} />
                    </strong>
                  </td>
                  <td>
                    {moment(requestLog.createdAt, DB_DATE_FORMAT).format(
                      'DD.MM.YYYY HH:mm:ss',
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <FormattedMessage {...messages.type} />
                    </strong>
                  </td>
                  <td>
                    {requestLog.type === 'UNAUTHORIZED_ACCESS' ? (
                      <span style={{ color: 'red' }}>
                        <FaExclamationTriangle /> {requestLog.type}
                      </span>
                    ) : (
                      requestLog.type
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <FormattedMessage {...messages.host} />
                    </strong>
                  </td>
                  <td>
                    {RequestLogsTable.getValueFromRequestHeaders({
                      requestLog,
                      key: 'host',
                    })}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <FormattedMessage {...messages.info} />
                    </strong>
                  </td>
                  <td>{requestLog.message}</td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <FormattedMessage {...messages.user} />
                    </strong>
                  </td>
                  <td>{this.getUsername()}</td>
                </tr>
                <tr>
                  <td>
                    <strong>
                      <FormattedMessage {...messages.requestData} />
                    </strong>
                  </td>
                  <td>
                    <JSONPretty
                      id="request-data"
                      data={requestLog.requestData}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    );
  }
}

const requestLogQuery = gql`
  query requestLogQuery($id: String!) {
    requestLog(id: $id) {
      id
      type
      message
      requestData
      createdAt
    }
  }
`;

export default compose(
  graphql(requestLogQuery, {
    name: 'requestLogQuery',
    options: ({ match }) => ({
      variables: {
        id: match.params.id,
      },
    }),
  }),
)(RequestLogView);
