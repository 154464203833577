/* eslint-disable import/prefer-default-export, prettier/prettier */

export const DB_DATE_FORMAT = 'ddd MMM D YYYY HH:mm:ss ZZ';
export const DATEPICKER_DATE_FORMAT = 'DD.MM.YYYY';

export const getRandomDigits = (length = 20, min = 0, max = 9) =>
    Array.from({ length }, () => Math.floor(Math.random() * ((max - min) + 1)) + min).join('');

export const isNumeric = (n) => 
    // eslint-disable-next-line
     !isNaN(parseFloat(n)) && isFinite(n)


/**
 * Adds a form with hidden inputs to the site and calls submit on it
 * this is done to perform a post request with the browser and follow redirects
 * 
 * TODO: add nested object and array support
 * @param {string} path the url to perform the request against
 * @param {object} params A object containing the formData to send
 */
export const post = (path, params) => {
    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('action', path);

    Object.keys(params).forEach((key => {
        const hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', key);
        hiddenField.setAttribute('value', params[key]);

        form.appendChild(hiddenField);
    }));

    document.body.appendChild(form);
    form.submit();
}
