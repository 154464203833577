/* eslint-disable react/prop-types, max-len, jsx-a11y/label-has-for */

import React from 'react';
import { change } from 'redux-form';
import Textarea from 'react-textarea-autosize';
import { FormattedMessage } from 'react-intl';
import FaTrash from 'react-icons/lib/fa/trash';

import formMessages from './messages';
import Spinner from '../Spinner';
import DatePicker from '../DatePicker';
import TimePicker from '../TimePicker';

// workaround for https://www.chromestatus.com/features/5093566007214080
const onTouchStart = event => {
  event.target.focus();
};

const wrapHorizontal = (id, component, label, required = false) => {
  if (!label) {
    return (
      <div key={`horizontal_component_${id}`} className="col-sm-8">
        {component}
      </div>
    );
  }
  return [
    <label key={`horizontal_label_${id}`} htmlFor={id} className="col-sm-4">
      {label}
      {required && (
        <span key={`horizontal_required_${id}`} className="required">
          *
        </span>
      )}
    </label>,
    <div key={`horizontal_component_${id}`} className="col-sm-8">
      {component}
    </div>,
  ];
};

const renderInput = ({
  input,
  label,
  type,
  meta: { asyncValidating, touched, error, warning },
  id,
  placeholder,
  disabled,
  horizontal = false,
  required = false,
  ...rest
}) => {
  const inputField = (
    <input
      {...input}
      {...rest}
      id={id}
      type={type}
      key={id}
      className="form-control"
      placeholder={placeholder}
      onTouchStart={onTouchStart}
      disabled={disabled}
      // ref={el => autoFocus && el && el.focus()}
    />
  );

  const component = horizontal
    ? wrapHorizontal(id, inputField, label, required)
    : [
        label && (
          <label htmlFor={id} key={`label_${id}`}>
            {label}
            {required && <span className="required">*</span>}
          </label>
        ),
        inputField,
      ];

  const errorClass = horizontal
    ? 'validationErrorHorizontal'
    : 'validationError';
  return (
    <div className="form-group">
      <Spinner show={asyncValidating} />
      {component}
      {touched &&
        ((error && <p className={errorClass}>{error}</p>) ||
          (warning && <p className="bg-warning">{warning}</p>))}
    </div>
  );
};

const renderFileInput = ({
  input,
  label,
  meta: { asyncValidating, touched, error, warning },
  id,
  onFileChange,
}) => (
  <div className="form-group">
    <Spinner show={asyncValidating} />
    {label && (
      <label
        htmlFor={id}
        className="btn btn-default btn-block btn-file btn-file"
      >
        {label}
      </label>
    )}
    <input
      id={id}
      type="file"
      className="form-control"
      style={label ? { display: 'none' } : {}}
      onTouchStart={onTouchStart}
      onChange={onFileChange}
    />
    {touched &&
      ((error && <p className="bg-danger">{error}</p>) ||
        (warning && <p className="bg-warning">{warning}</p>))}
  </div>
);

const renderCheckbox = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
  id,
  style,
  disabled,
}) => (
  <div className="checkbox" style={style}>
    <label htmlFor={id}>
      <input {...input} id={id} type={type} disabled={disabled} />
      {label}
    </label>
    {touched &&
      ((error && <p className="bg-danger">{error}</p>) ||
        (warning && <p className="bg-warning">{warning}</p>))}
  </div>
);

const renderRadio = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
  id,
}) => (
  <div className="radio">
    <label htmlFor={id}>
      <input {...input} id={id} type={type} />
      {label}
    </label>
    {touched &&
      ((error && <p className="bg-danger">{error}</p>) ||
        (warning && <p className="bg-warning">{warning}</p>))}
  </div>
);

const renderSelect = ({
  input,
  label,
  meta: { touched, error, warning },
  id,
  children,
  disabled,
}) => (
  <div className="form-group">
    {label && <label htmlFor={id}>{label}</label>}
    <select {...input} id={id} className="form-control" disabled={disabled}>
      {children}
    </select>
    {touched &&
      ((error && <p className="bg-danger">{error}</p>) ||
        (warning && <p className="bg-warning">{warning}</p>))}
  </div>
);

const renderTextarea = ({
  input,
  label,
  meta: { touched, error, warning },
  id,
  placeholder,
}) => (
  <div className="form-group">
    {label && <label htmlFor={id}>{label}</label>}
    <Textarea
      {...input}
      id={id}
      className="form-control"
      placeholder={placeholder}
    >
      {input.value}
    </Textarea>
    {touched &&
      ((error && <p className="bg-danger">{error}</p>) ||
        (warning && <p className="bg-warning">{warning}</p>))}
  </div>
);

// eslint-disable-next-line no-unused-vars
const renderNumberInput = ({ input, label, meta, id }) => {
  function increaseNumber() {
    meta.dispatch(change(meta.form, input.name, Number(input.value) + 1));
  }

  function decreaseNumber() {
    meta.dispatch(change(meta.form, input.name, Number(input.value) - 1));
  }

  return (
    <div style={{ padding: '0 20px 20px 20px' }}>
      <button
        type="button"
        className="btn btn-primary"
        style={{ width: '100%' }}
        onClick={increaseNumber}
      >
        <i className="fa fa-plus" />
      </button>
      <input
        {...input}
        className="no-spinners"
        type="number"
        style={{ width: '100%', textAlign: 'center' }}
        min={0}
      />
      <button
        type="button"
        className="btn btn-primary"
        style={{ width: '100%' }}
        onClick={decreaseNumber}
      >
        <i className="fa fa-minus" />
      </button>
    </div>
  );
};

const renderTime = ({
  input,
  label,
  meta: { touched, error, warning },
  id,
  formName,
  interval,
  handleUpdate,
}) => (
  <div className="form-group">
    {label && <label htmlFor={id}>{label}</label>}
    <div>
      <TimePicker
        meta={{
          touched,
          error,
          warning,
        }}
        input={input}
        formConfig={{
          formName,
          fieldName: input.name,
        }}
        interval={interval}
        handleUpdate={handleUpdate}
      />
    </div>
  </div>
);

// eslint-disable-next-line react/prop-types, max-len, prettier/prettier
const renderDate = ({ input, label, meta: { touched, error, warning }, id, placeholder }) => (
  <div className="form-group">
    {label && <label htmlFor={id}>{label}</label>}
    <div className="input-group">
      <DatePicker
        input={input}
        meta={{
          touched,
          error,
          warning,
        }}
        className="form-control"
        placeholder={placeholder}
      />
    </div>
  </div>
);

/* eslint-disable react/no-array-index-key */
const renderGenericFieldArray = ({
  fields,
  meta: { error },
  name,
  renderField,
  withAdd = true,
  withRemove = true,
  pushValue,
  singleRows,
}) => {
  const classNames = ['field-array'];
  const removeButtonClassNames = ['btn', 'btn-red'];

  if (singleRows) {
    classNames.push('field-array--single-rows');
    removeButtonClassNames.push('btn--no-border');
    removeButtonClassNames.push('btn--no-hover');
    removeButtonClassNames.push('remove-button');
  }

  return (
    <ul className={classNames.join(' ')}>
      {fields.map((field, index) => (
        <li key={`${name}__${index}`} className="clearfix">
          {renderField(field, index, fields)}
          {withRemove && (
            <button
              type="button"
              className={removeButtonClassNames.join(' ')}
              onClick={() => fields.remove(index)}
            >
              <FaTrash />
              {!singleRows && <FormattedMessage {...formMessages.remove} />}
            </button>
          )}
        </li>
      ))}
      {error && <li className="error">{error}</li>}
      {withAdd && (
        <li>
          <hr />
          <button
            type="button"
            className="btn btn-green"
            onClick={() => fields.push(pushValue)}
          >
            <i className="fa fa-plus fa--prepended" />
            <FormattedMessage {...formMessages.add} />
          </button>
        </li>
      )}
    </ul>
  );
};
/* eslint-enable react/no-array-index-key */

export default {
  renderInput,
  renderFileInput,
  renderCheckbox,
  renderRadio,
  renderSelect,
  renderTextarea,
  renderNumberInput,
  renderTime,
  renderDate,
  renderGenericFieldArray,
};
