import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { Navbar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import s from './TopNavigation.scss'; // eslint-disable-line css-modules/no-unused-class

class TopNavigation extends React.Component {
  render() {
    return (
      <Navbar
        className={s.navbar}
        fixedTop
        fluid
        collapseOnSelect
        onSelect={k => this.handleSelect(k)}
      >
        <Navbar.Header>
          <Navbar.Brand className={s.logoContainer}>
            <Link to="/admin/dashboard">
              <div className={`${s.logoIcon} ${s.hideMobile}`}>
                <img src="/logo.png" alt="" />
                <span>- Admin</span>
              </div>
              <div className={`${s.logoIcon} ${s.showMobile}`}>
                <img src="/logo_no_text.png" alt="" />
              </div>
            </Link>
          </Navbar.Brand>
        </Navbar.Header>
      </Navbar>
    );
  }
}

export default withStyles(s)(TopNavigation);
