import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import gql from 'graphql-tag';
import copy from 'copy-to-clipboard';

import ApiTokenGeneratorForm from './apiTokenGeneratorForm';
import { Alert } from '../../Modal';

// eslint-disable-next-line css-modules/no-unused-class
import ModalStyle from '../../Modal/Modal.scss';
import messages from './messages';

const apiTokenForApiKey = gql`
  query apiTokenForApiKey($apiKeyId: String!, $secret: String!) {
    apiTokenForApiKey(apiKeyId: $apiKeyId, secret: $secret)
  }
`;

class ApiTokenGenerator extends React.Component {
  static contextTypes = {
    client: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.handleGenerateApiToken = this.handleGenerateApiToken.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleCopy = this.handleCopy.bind(this);

    this.state = { generatedApiToken: null, showModal: false };
  }

  handleCopy() {
    const { generatedApiToken } = this.state;
    if (generatedApiToken) {
      copy(generatedApiToken);
    }
  }

  closeModal() {
    this.setState({
      showModal: false,
    });
  }

  async handleGenerateApiToken(formValues) {
    if (formValues.apiKeyId && formValues.secret) {
      const queryResult = await this.context.client.query({
        query: apiTokenForApiKey,
        variables: {
          apiKeyId: formValues.apiKeyId,
          secret: formValues.secret,
        },
        fetchPolicy: 'network-only',
      });

      if (queryResult.data) {
        this.setState({
          showModal: true,
          generatedApiToken: queryResult.data.apiTokenForApiKey,
        });
      }
    }
  }

  renderSuccessDialog() {
    const { generatedApiToken } = this.state;
    return (
      <Alert
        show={this.state.showModal}
        dialogClassName={`${ModalStyle.defaultDialog} ${ModalStyle.dialog}`}
        title={<FormattedMessage {...messages.modalHeaderSuccess} />}
        msg={
          <div>
            <div>
              <FormattedMessage {...messages.successMessage1} />
            </div>
            <br />
            <div style={{ textAlign: 'center', wordBreak: 'break-all' }}>
              <b>{generatedApiToken}</b>
            </div>
            <br />
          </div>
        }
        footer={
          <div>
            <button
              className="btn-secondary btn-round"
              style={{ margin: '0 10px' }}
              onClick={this.handleCopy}
            >
              <FormattedMessage {...messages.copy} />
            </button>
            <button
              className="btn-primary btn-round"
              style={{ margin: '0 10px' }}
              onClick={this.closeModal}
            >
              <FormattedMessage {...messages.close} />
            </button>
          </div>
        }
        hide={this.closeModal}
      />
    );
  }

  render() {
    const { generatedApiToken } = this.state;

    return (
      <div>
        <ApiTokenGeneratorForm onSubmit={this.handleGenerateApiToken} />
        {generatedApiToken && this.renderSuccessDialog()}
      </div>
    );
  }
}

export default injectIntl(ApiTokenGenerator);
