/* eslint-disable camelcase */

import React from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import FaEdit from 'react-icons/lib/fa/edit';

import Loading from 'components/Loading';
import tableMessages from 'components/Table/messages';
import Table from 'components/Table';

import messages from './messages';

class UserTable extends React.Component {
  static propTypes = {
    usersQuery: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      api_users: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          username: PropTypes.string.isRequired,
        }),
      ),
    }).isRequired,
    match: PropTypes.shape({
      path: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.createColumns = this.createColumns.bind(this);
  }

  createColumns() {
    const {
      match: { path },
    } = this.props;

    const columns = [
      {
        Header: <FormattedMessage {...messages.username} />,
        width: 350,
        Cell: ({ original }) => <span>{original.username}</span>,
      },
      {
        Header: <FormattedMessage {...tableMessages.actions} />,
        Cell: ({ original }) => (
          <Link to={`${path}${original.username}/edit`}>
            <FaEdit />
          </Link>
        ),
      },
    ];

    return columns;
  }

  render() {
    const {
      usersQuery: { loading, api_users },
    } = this.props;
    if (loading) return <Loading />;

    const columns = this.createColumns();
    return (
      <Table
        keyField="username"
        data={api_users}
        columns={columns}
        tableProps={{ defaultPageSize: 10 }}
      />
    );
  }
}

const usersQuery = gql`
  query users {
    api_users {
      id
      username
    }
  }
`;

export default compose(
  graphql(usersQuery, {
    name: 'usersQuery',
  }),
)(UserTable);
